import React, { useEffect, useRef, useState } from "react";
import "./styles.css";

import {
  URLS,
  ICE_SERVERS,
  ENABLE_WEBRTC_DEBUG_LOGGING,
  SIGNALING_SERVER_URL,
} from "../../constants";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";

import { insights } from "../../ApplicationInsightsService";
import Moveable from "react-moveable";
import { getMediaMimeType } from "../../utils/mediaFunctions";

let updatingEventInfo = false;
let peerController = null;
let peerControllerChannel = "";
let currentVideoElement = null;
let currentUserStream = null;
let blurringInterval = null;
let orientationInterval = null;

let outputSessionId = window.crypto.randomUUID() + "_" + Date.now().toString();
let cssTextUpdated = null;

let channelInLatestCommand = "";

function setupCommandListener({
  setError,
  onVideoLoading,
  onConnected,
  onVideoNone
}) {
  const eventId = window.location.hash.replace("#", "");
  if (!eventId) {
    setError("Not found");
  }
  const source = new EventSource(
    URLS.EVENT_SCREEN_COMMAND_SUSCRIBE_LINK(eventId, "tok")
  );
  source.addEventListener("update", (ev) => {
    idleTimer = 0;
    const data = JSON.parse(ev.data);
    const payload = data.payload;
    try {
      const parsed = JSON.parse(payload);
      if(parsed.channel === channelInLatestCommand) {
        console.log(`Ignoring command to connect to already connected channel: ${parsed.channel}`);
        return;
      }
      channelInLatestCommand = parsed.channel;
      if (parsed.type === "command") {
        if (parsed.channel === "none") {
          disconnectCurrentPeerConnection();
          onVideoNone();
          insights.trackUvenuClick("Output Screen: No connected users", {
            status: "success",
            info: "received signal for user clearance in output",
            oldChannel: peerControllerChannel,
            outputSessionId: outputSessionId,
          });
          peerControllerChannel = "";
          return;
        }
        if (parsed.channel) {
          console.log(`received command to connect to channel ${parsed.channel}, currently connected to ${peerControllerChannel}`);
          const splitArray = parsed.channel.split("#");
          connectToChannel(splitArray[0], onVideoLoading, onConnected);
        }
      }
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        error: error,
        outputSessionId: outputSessionId,
      });
    }
  });

  return () => {
    source.close();
  };
}

async function disconnectCurrentPeerConnection() {
  if (peerController) {
    try {
      // very important to set peerController global 'null'
      // before awaiting on disconnect.
      // because if the new stream is received before the
      // await disconnect resolves, it will set peerController
      // to the new pc and the peerController = null
      // will leak the new peerController
      const currentPeerController = peerController;
      peerController = null;
      await currentPeerController.disconnect();
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "Output Page: peerController.disconnect error",
        error: error,
        outputSessionId: outputSessionId,
      });
    }
  }
}

function connectToChannel(channel, onLoading, onComplete) {
  const pc = new window.SimplePeerController({
    roomId: channel,
    baseUrl: SIGNALING_SERVER_URL,
    EventSource: EventSource,
    iceServers: ICE_SERVERS,
    noConfirmation: true,
    enableDebugLogging: ENABLE_WEBRTC_DEBUG_LOGGING,
    sdpTransform: (s) => { return s; },
  });

  pc.on("connecting", () => {
    if (pc === peerController) {
      onLoading();
    }
    console.log("connecting...");
  });
  pc.on("reconnecting", () => {
    if (pc === peerController) {
      onLoading();
    }
    console.log("reconnecting...");
  });
  pc.on("connected", () => {
    console.log("waiting...");
  });
  pc.on("stream", (stream) => {
    insights.trackUvenuClick("Output Screen: Remove Previous Room", {
      status: "success",
      oldChannel: peerControllerChannel,
      outputSessionId: outputSessionId,
    });
    console.log("received a stream...");
    currentUserStream = stream;
    // after receiving the stream, and before
    // assigning this new stream to the video element
    // we trigger onLoading, and after half a second
    // we trigger onComplete. This gives the impression of a very quick
    // switch.
    onLoading();
    setTimeout(() => {
      // we cannot call disconnect outside because
      // then the video element will go black.
      disconnectCurrentPeerConnection();
      peerController = pc;
      assignStreamToVideo(stream);
      onComplete();
    }, 500);

    insights.trackUvenuClick("Output Screen: Switch Room", {
      status: "success",
      oldChannel: peerControllerChannel,
      newChannel: channel,
      outputSessionId: outputSessionId,
    });
    peerControllerChannel = channel;
  });
}

function assignStreamToVideo(stream) {
  currentUserStream = stream;
  currentVideoElement && (currentVideoElement.srcObject = stream);
}

let updateOrientation = () => {};
let onImageFrameProcessed = () => {};
let onVideoElementReceived = () => {};
function receiveVideoElementRef(r) {
  if (r) {
    currentVideoElement = r;
    currentUserStream && (r.srcObject = currentUserStream);
    onVideoElementReceived();
    updateOrientation();
  }
}

let idleTimer = 0;
let onBackgroungConfigRefreshed = () => {};
let onOverlayImageRefreshed = () => {};
let applyMovableStyles = () => {};
function VideoView({ showVideoElement = false }) {
  function startDrawingOnCanvas(videoElement, canvasElement) {
    function draw(v, c, w, h) {
      if (v.paused || v.ended) return false;

      drawCover(c, v, w, h);
    }

    function drawCover(ctx, vid, w, h) {
      // Store variables
      let cw = ctx.canvas.clientWidth;
      let ch = ctx.canvas.clientHeight;
      let vw = vid.videoWidth;
      let vh = vid.videoHeight;

      // Bail when video is full width
      // if (cw <= vid.clientWidth) return false;

      // Prevent black flash
      if (vid.currentTime === 0) return false;

      let r = 0;
      ctx.canvas.width = ctx.canvas.clientWidth;
      ctx.canvas.height = ctx.canvas.clientHeight;
      let rw = cw + 2 * r;
      let rh = (rw * vh) / vw;
      // ctx.filter = "blur(" + r + "px)";
      // Cover
      ctx.drawImage(vid, 0, 0, vw, vh, -r, (ch - rh) / 2, rw, rh);
      // Sretch
      ctx.drawImage(vid, 0, 0, cw, ch);

      let image = "" + ctx.canvas.toDataURL("image/png");
      if (image.length > 10) {
        onImageFrameProcessed(ctx.canvas.toDataURL("image/png"), vw, vh);
      }
    }

    let cw = canvasElement.clientWidth;
    let ch = canvasElement.clientHeight;

    if (blurringInterval) {
      clearInterval(blurringInterval);
    }

    blurringInterval = setInterval(() => {
      if (videoElement.paused) {
        return;
      }
      window.requestAnimationFrame(() => {
        const context = canvasElement.getContext("2d");
        draw(videoElement, context, cw, ch);
      });
    }, 1000 / 30);
  }

  const [backgroundBlurEnabled, setBackgroundBlurEnabled] = useState(false);

  onBackgroungConfigRefreshed = (event) => {
    setBackgroundBlurEnabled(event.brandingBackgroundBlurEnabled);
  };

  const [isLandscape, setIsLandscape] = useState(false);
  updateOrientation = () => {
    if (orientationInterval) {
      clearInterval(orientationInterval);
    }
    orientationInterval = setInterval(() => {
      const width = currentVideoElement.videoWidth;
      const height = currentVideoElement.videoHeight;
      if (width > height) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    }, 1000 / 30);
  };

  let blurringCanvasRef = useRef(null);
  applyMovableStyles = () => {
    updatecss();
  };

  const updatecss = () => {
    if (blurringCanvasRef.current !== null)
      blurringCanvasRef.current.style.cssText += cssTextUpdated;

    const userVideoElement = document.getElementById("user-video-element");
    if (userVideoElement !== null) {
      userVideoElement.style.cssText += cssTextUpdated;
      userVideoElement.style.clipPath = "none";
    }
  };

  useEffect(() => {
    updatecss();
  });

  // This useEffect handled the blurring in and out of the video element.
  useEffect(() => {
    if(!currentVideoElement) return;
    if (showVideoElement) {
      setTimeout(() => {
        currentVideoElement.style.opacity = 1;
      }, 10);
    } else {
      setTimeout(() => {
        currentVideoElement.style.opacity = 0;
      });
    }
  }, [showVideoElement]);

  return (
    <React.Fragment>
      {backgroundBlurEnabled && (
        <canvas
          ref={(canvas) => {
            if (canvas) {
              blurringCanvasRef.current = canvas;
              if (currentVideoElement) {
                startDrawingOnCanvas(currentVideoElement, canvas);
              }
              onVideoElementReceived = () => {
                startDrawingOnCanvas(currentVideoElement, canvas);
              };
            }
          }}
          className="userOutputCanvas"
          style={{ display: backgroundBlurEnabled ? undefined : "none" }}
        />
      )}
      <video
        className={"userOutputVideoElement"}
        style={{
          width: isLandscape ? "100vw" : "50vw",
        }}
        id="user-video-element"
        autoPlay
        ref={receiveVideoElementRef}
        muted={true}
        playsInline
        disableRemotePlayback={true}
      />
    </React.Fragment>
  );
}

export default (function () {
  const eventId = window.location.hash.replace("#", "");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [overlayImageEnabled, setOverlayImageEnabled] = useState(false);
  const [overlayImageUri, setOverlayImageUri] = useState(null);
  const [overlayImageMimeType, setOverlayImageMimeType] = useState("image");

  const [backgroundColorEnabled, setBackgroundColorEnabled] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(null);

  const [backgroundImageEnabled, setBackgroundImageEnabled] = useState(false);
  const [backgroundImageUri, setBackgroundImageUri] = useState(null);
  const [backgroundImageMimeType, setBackgroundImageMimeType] =
    useState("image");

  const lastFrameImage = useRef(null);
  const lastFrameWidth = useRef(null);
  const lastFrameHeight = useRef(null);

  useEffect(() => {
    return setupCommandListener({
      setError,
      onVideoLoading: () => {
        setLoading(true);
      },
      onConnected: () => {
        setLoading(false);
      },
      onVideoNone: () => {
        setLoading(true);
      }
    });
  }, [error, lastFrameImage, overlayImageUri]);

  useEffect(() => {
    updateEventInfo();
  }, [eventId]);

  const updateEventInfo = async () => {
    await eventInfoAPI();
    await eventOutputSettingsAPI();

    const settingsInterval = setInterval(async () => {
      if (updatingEventInfo) return;
      if (idleTimer > 30) {
        clearInterval(settingsInterval);
        return;
      }
      idleTimer++;
      await eventOutputSettingsAPI();
    }, 10000);
  };

  const eventInfoAPI = async () => {
    if (updatingEventInfo) {
      return;
    }
    updatingEventInfo = true;
    try {
      const response = await uvenuFetcher({
        method: "GET",
        url: URLS.EVENT_INFO(eventId),
      });
      if (response.statusCode === 200) {
        // onOverlayImageRefreshed(response.json.data);
        // onBackgroungConfigRefreshed(response.json.data);
        updatingEventInfo = false;
      } else {
        console.log(response.json.message);
        let error = Error(response.json.message);
        insights.trackUvenuException(error, {
          status: "failed",
          info: "Output Page: Error while fetching event info",
          message: response.json.message,
          outputSessionId: outputSessionId,
        });
      }
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "Output Page: Exception while fetching event info",
        error: error,
        outputSessionId: outputSessionId,
      });
    }
  };

  const eventOutputSettingsAPI = async () => {
    if (updatingEventInfo) {
      return;
    }
    updatingEventInfo = true;
    try {
      const response = await uvenuFetcher({
        method: "GET",
        url: URLS.EVENT_SETTINGS(eventId),
      });
      if (response.statusCode === 200) {
        onOverlayImageRefreshed(response.json.data);
        onBackgroungConfigRefreshed(response.json.data);
        updatingEventInfo = false;
      } else {
        console.log(response.json.message);
        let error = Error(response.json.message);
        insights.trackUvenuException(error, {
          status: "failed",
          info: "Output Page: Error while fetching event output settings",
          message: response.json.message,
          outputSessionId: outputSessionId,
        });
      }
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "Output Page: Exception while fetching event output settings",
        error: error,
        outputSessionId: outputSessionId,
      });
    }
  };

  onImageFrameProcessed = (imageBase64, width, height) => {
    lastFrameImage.current = imageBase64;
    lastFrameWidth.current = width;
    lastFrameHeight.current = height;
  };

  onOverlayImageRefreshed = (event) => {
    setOverlayImageEnabled(event.brandingOverlayImageEnabled);
    setOverlayImageUri(event.brandingOverlayImageUrl);
    getMediaMimeType(event.brandingOverlayImageUrl).then((type) => {
      setOverlayImageMimeType(type);
    });

    setBackgroundColorEnabled(event.brandingBackgroundColorEnabled);
    setBackgroundColor(event.brandingBackgroundColor);
    setBackgroundImageEnabled(event.brandingBackgroundImageEnabled);
    getMediaMimeType(event.brandingBackgroundImageUrl).then((type) => {
      setBackgroundImageMimeType(type);
    });
    setBackgroundImageUri(event.brandingBackgroundImageUrl);
  };

  let targetRef = useRef(null);
  let movableRef = useRef(null);
  let backgroundRef = useRef(null);
  let overlayRef = useRef(null);
  const [mouseClicked, setMouseClicked] = useState(false);
  useEffect(() => {
    const handleClick = (e) => {
      if (targetRef.current.contains(e.target)) {
        setMouseClicked(true);
      } else {
        setMouseClicked(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    movableRef.current && movableRef.current.updateRect();
  }, [mouseClicked]);

  return (
    <>
      <div
        id={`videoview`}
        ref={targetRef}
        className="userOutputVideoView"
        style={{
          top: mouseClicked ? "2%" : "0px",
          left: mouseClicked ? "2%" : "0px",
          height: mouseClicked ? "96%" : "100%",
          width: mouseClicked ? "96%" : "100%",
        }}
      >
        {loading &&
          lastFrameImage.current ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 222,
              }}
            >
              <img
                alt={"logo"}
                src={lastFrameImage.current}
                style={{
                  height: "100%",
                  width: "100%",
                  filter: "blur(15px)",
                }}
              />
              <img
                alt={"logo"}
                src={lastFrameImage.current}
                style={{
                  height: "100%",
                  width:
                    lastFrameWidth.current < lastFrameHeight.current
                      ? "35%"
                      : "100%",
                  position: "absolute",
                  objectFit: "fill",
                }}
              />
            </div>
          ) : (
            <span />
          )
        }

        <VideoView showVideoElement={!loading}/>

        {backgroundImageEnabled &&
          (backgroundImageMimeType.includes("video") ? (
            <video
              ref={backgroundRef}
              autoPlay
              playsInline
              loop
              className="outputBackgroundImage"
              controls={false}
              muted
              src={backgroundImageUri || "#"}
              disableRemotePlayback={true}
            />
          ) : (
            <img
              ref={backgroundRef}
              alt={"bg"}
              src={backgroundImageUri}
              className="outputBackgroundImage"
            />
          ))}
        {backgroundColorEnabled && (
          <span
            ref={backgroundRef}
            style={{ backgroundColor: backgroundColor }}
            className="outputBackgroundImage"
          />
        )}
        {overlayImageEnabled &&
          overlayImageUri &&
          (overlayImageMimeType.includes("video") ? (
            <video
              ref={overlayRef}
              autoPlay
              playsInline
              loop
              className="outputBrandOverlay"
              controls={false}
              muted
              src={overlayImageUri || "#"}
              disableRemotePlayback={true}
            />
          ) : (
            <img
              ref={overlayRef}
              alt={"overlay"}
              src={overlayImageUri}
              className="outputBrandOverlay"
            />
          ))}
      </div>
      <Moveable
        ref={movableRef}
        target={targetRef}
        origin={false}
        hideDefaultLines={!mouseClicked}
        // roundable={mouseClicked}
        isDisplayShadowRoundControls={"horizontal"}
        roundClickable={"line"}
        roundPadding={15}
        onRender={(e) => {
          targetRef.current.style.cssText += e.cssText;
          if (backgroundRef.current !== null)
            backgroundRef.current.style.cssText += e.cssText;
          if (overlayRef.current !== null)
            overlayRef.current.style.cssText += e.cssText;

          cssTextUpdated = e.cssText;
          applyMovableStyles();
        }}
        onRenderEnd={(e) => {
          targetRef.current.style.cssText += e.cssText;
          if (backgroundRef.current !== null)
            backgroundRef.current.style.cssText += e.cssText;
          if (overlayRef.current !== null)
            overlayRef.current.style.cssText += e.cssText;

          cssTextUpdated = e.cssText;
          applyMovableStyles();
        }}
        clippable={mouseClicked}
        onClip={(e) => {
          e.target.style.clipPath = e.clipStyle;
        }}
      />
    </>
  );
});
