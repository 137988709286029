import React, { useEffect, useRef } from "react";
import { Button, Typography } from "@mui/material";
import LiveOutputViewV2 from "./LiveOutputViewV2";
import { connect } from "redux-zero/react";
import { getVideoTrackFromPeerConnection } from "../../../utils/getVideoTrackFromPeerConnection";
import { startDrawingOnCanvas } from './startDrawingOnCanvas';
import actions from "../../../store/actions";
import "../styles.css";
import { COLORS } from "../../../utils/colors";

function tryAttachStreamToVideoElement(
  activeConnections,
  currentlySelectedUserId,
  videoElement
) {
  if (!videoElement.current) return true;
  const videoTrack = getVideoTrackFromPeerConnection(
    activeConnections[currentlySelectedUserId]
  );
  if (!videoTrack) return false;
  const stream = new MediaStream([videoTrack]);
  videoElement.current.srcObject = stream;
  return true;
}

function LoadPreviewForSelectedUser({
  event,
  activeConnections,
  currentlySelectedUserId,
  currentlyStreamingUser,
  setCurrentlyStreamingUser,
  setCurrentlySelectedUserId,
}) {
  const videoElement = useRef(null);
  const canvasElement = useRef(null);

  const isUserSelected = Object.keys(activeConnections).includes(
    currentlySelectedUserId
  );

  useEffect(() => {
    const success = tryAttachStreamToVideoElement(
      activeConnections,
      currentlySelectedUserId,
      videoElement
    );
    // return if successful the first time.
    if (success) return;
    // keep trying to attach element until success or until unmount.
    const retryInterval = setInterval(() => {
      const stopTrying = tryAttachStreamToVideoElement(
        activeConnections,
        currentlySelectedUserId,
        videoElement
      );
      if (stopTrying) {
        clearInterval(retryInterval);
      }
    }, 500);
    return () => {
      clearInterval(retryInterval);
    };
  }, [currentlySelectedUserId, activeConnections]);

  const checkAndStartDrawingOnCanvas = () => {
    if (videoElement.current && canvasElement.current) {
      startDrawingOnCanvas(videoElement.current, canvasElement.current);
    }
  };

  const PreviewSection = (() => {
    if (!isUserSelected) {
      return (
        <div className="noUserSection">
          <img
            style={{ height: 300, width: 300, objectFit: "contain" }}
            alt={"logo"}
            src={event.eventImageUrl}
          />
          <Typography className="infoText" style={{ color: COLORS.textHeader }}>
            Please select a user
          </Typography>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {event.brandingBackgroundImageEnabled && (
            <img
              src={event.brandingBackgroundImageUrl}
              className="backgroundImage"
            />
          )}
          {event.brandingBackgroundColorEnabled && (
            <span
              style={{ backgroundColor: event.brandingBackgroundColor }}
              className="backgroundImage"
            />
          )}
          <div className="veVideoElementContainer">

          {event.brandingBackgroundBlurEnabled && (
            <canvas
              ref={(canvas) => {
                canvasElement.current = canvas;
                checkAndStartDrawingOnCanvas();
              }}
              className="veCanvasStyle"
            />
          )}
            <video
              className="veVideoElement"
              autoPlay
              ref={(r) => {
                videoElement.current = r;
                checkAndStartDrawingOnCanvas();
              }}
              muted
              playsInline
              disableRemotePlayback={true}
            />
          </div>
          <Button
            variant="contained"
            size="small"
            className="liveStreamingButton"
            disabled={
              currentlyStreamingUser &&
              currentlyStreamingUser.userId === currentlySelectedUserId
            }
            sx={{
              // display: showStreamButton ? undefined : "none",
              color: COLORS.textHeader,
            }}
            onClick={async () => {
              setCurrentlyStreamingUser(currentlySelectedUserId);
              setCurrentlySelectedUserId("");
            }}
          >
            Stream This
          </Button>
        </React.Fragment>
      );
    }
  })();

  return (
    <div
      className="videoSection"
      style={{ backgroundColor: COLORS.previewViewBackground }}
    >
      <div
        className="liveOutputView"
        style={{ backgroundColor: COLORS.popupBackground }}
      >
        <LiveOutputViewV2 event={event} />
      </div>
      {PreviewSection}
    </div>
  );
}

export default connect(
  ({ initialState: { activeConnections, currentlySelectedUserId, currentlyStreamingUser } }) => ({
    activeConnections,
    currentlySelectedUserId,
    currentlyStreamingUser
  }),
  actions
)(LoadPreviewForSelectedUser);
