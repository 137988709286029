import React, { useEffect, useRef, useState } from "react";
import { CardActionArea, Typography } from "@mui/material";
import { Stop } from "@mui/icons-material";
import { sendCommandToMainScreen } from ".";
import { connect } from 'redux-zero/react';
import actions from '../../../store/actions';
import { getVideoTrackFromPeerConnection } from '../../../utils/getVideoTrackFromPeerConnection';
import Loader from '../../Social/Loader_social';

import "../styles.css";
import { COLORS } from "../../../utils/colors";

/**
 * This is a very important component and it handles the streaming to
 * main output screen. Hence, this is not just a UI component and
 * is responsible for some very critical state management.
 * In summary, this component watches the `currentlyStreamingUser` store variable
 * and when it changes - meaning another user was selected for streaming - it calls
 * connectoToMainScreen() to prepare a peer connection that streams the video of the
 * currently selected user, and then it calls sendCommandToMainScreen to also notify
 * the main output screen of this change.
 * @param {*} param0
 * @returns
 */
function LiveOutputViewV2({
  event,
  connectToMainScreen,
  currentlyStreamingUser,
  mainScreenPC,
  mainScreenPCChannel,
  setCurrentlyStreamingUser,
}) {
  const videoElement = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentlyStreamingUser.userId || !currentlyStreamingUser.pc) {
      connectToMainScreen('none');
      sendCommandToMainScreen(event._id, 'none');
      setLoading(false);
      return;
    };

    const userId = currentlyStreamingUser.userId;
    const channelName = `${event._id}_${userId}_main_screen`;

    if (!mainScreenPC || mainScreenPCChannel !== channelName) {
      // connect to main screen on the new channel.
      connectToMainScreen(channelName);
      // make the main screen connect to us on the new channel.
      sendCommandToMainScreen(event._id, channelName);
      // connectToMainScreen will re render this hook.
      return;
    }

    const attemptToAttachVideoToElement = () => {
      // grab the video track of the currently streaming user
      const videoTrack = getVideoTrackFromPeerConnection(
        currentlyStreamingUser.pc
      );
      if (!videoTrack) return false;
      // try attaching it to the local video element
      // but don't re-attach it if its already attached.
      if (!videoElement.current) return false;
      if (
        !videoElement.current.srcObject ||
        !videoElement.current.srcObject.getVideoTracks().includes(videoTrack)
      ) {
        videoElement.current.srcObject = new MediaStream([videoTrack]);
      }
      return true;
    }

    // set loading, and attempt once to attach video.
    // if this succeeds set loading false and return.
    // Else, the connection has not recieved the video track
    // until now, so go into a retry interval to attach the
    // track when it becomes available.
    setLoading(true);
    if(attemptToAttachVideoToElement()) {
      setLoading(false);
      return;
    }

    const attachVideoStreamInterval = setInterval(async () => {
      const success = attemptToAttachVideoToElement();
      if(!success) return;
      clearInterval(attachVideoStreamInterval);
      setLoading(false);
    }, 3000);

    // return cleanup callback for attach video stream interval.
    return () => {
      clearInterval(attachVideoStreamInterval);
    };
  }, [
    connectToMainScreen,
    currentlyStreamingUser,
    event._id,
    mainScreenPC,
    mainScreenPCChannel,
  ]);

  const stopClicked = async () => {
    videoElement.current.srcObject = undefined;
    setCurrentlyStreamingUser(null);
  };

  return (
    <div className="mainImgDiv">
      <CardActionArea
        disableRipple
        className="outputPreviewHolderDiv"
        style={{
          backgroundColor: COLORS.previewViewBackground,
          "&:hover": {
            backgroundColor: COLORS.previewViewBackground,
          },
        }}
      >
        {loading && <Loader />}
        <video
          className="outputPreviewVideo"
          autoPlay
          muted
          playsInline
          disableRemotePlayback={true}
          style={loading ? { display: "none" } : undefined}
          ref={(r) => {
            videoElement.current = r;
          }}
        />
        <div className="infoActionsView" style={{ top: 0, height: "100%" }}>
          <Typography
            className="liveOutputText"
            style={{ color: COLORS.error, backgroundColor: COLORS.textHeader }}
          >
            LIVE OUTPUT
          </Typography>
          <div
            className="stopIconHolder"
            style={{ backgroundColor: COLORS.popupBackground }}
          >
            <div
              onClick={() => {
                stopClicked();
                setTimeout(() => {
                  stopClicked();
                }, 500);
              }}
            >
              <Stop className="stopIconStyle" style={{ color: COLORS.error }} />
            </div>
          </div>
        </div>
      </CardActionArea>
    </div>
  );
}

export default connect(({ initialState: { currentlyStreamingUser, mainScreenPC, mainScreenPCChannel } }) => ({ currentlyStreamingUser, mainScreenPC, mainScreenPCChannel }), actions)(LiveOutputViewV2);