import { memo } from "react";
import TwoToneSplit from "./TwoToneSplit";
import TwoColorOpacity from "./TwoColorOpacity";
import SingleColorOpacity from "./SingleColorOpacity";
import RandomColorStrobe from "./RandomColorStrobe";

const LightShowEffects = memo(
  ({
    effect,
    color,
    color2,
    color1HoldTime,
    color2HoldTime,
    animationDuration
  }) => {
    return  (
      <>
        {effect === 1 ? (
          <TwoToneSplit
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
        {effect === 2 ? (
          <SingleColorOpacity
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
        {effect === 3 ? (
          <TwoColorOpacity
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
        {effect === 4 ? (
          <RandomColorStrobe
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
      </>
    );
  }
);
export default LightShowEffects;
